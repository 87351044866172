import React from "react";
import { styled } from '@mui/material/styles';
import footerStyles from './FooterStyles';
import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import LanguageIcon from '@mui/icons-material/Language';

type FooterProps = {

};

const StyledFooter = styled('footer')(({ theme }) => footerStyles);

const Footer: React.FC<FooterProps> = () => {
  return (
    <section className="bg-[#212121] flex items-center justify-center flex-col">
      <div className="container">
        <StyledFooter>
          <div className="footer-links-section">
          <ul>
            <h1 className="footer-link-title">Mapa del sitio</h1>
              <li className="footer-link">
                Servicios
              </li>
              <li className="footer-link">
                Nosotros
              </li>
              <li className="footer-link">
                Contacto
              </li>
          </ul>
          <ul>
            <h1 className="footer-link-title">Contacto</h1>
              <div className="footer-text">
                +52 775-155-8565
              </div>
              <div className="footer-text">
                +52 771-158-0540
              </div>
          </ul>
          <ul>
            <h1 className="footer-link-title">Ubicación</h1>
              <div className="footer-text">
                México Km 60, Santa Gertrudis, 43855 Zempoala, Hgo.
              </div>
          </ul>
          </div>
          <div className="footer-policy-section">
            <span>© 2024 Derechos reservados.</span>
            <span>Terms · Privacy Policy</span>
            <div>
              <span className="footer-media-button">
                <InstagramIcon></InstagramIcon>
              </span>
              <span className="footer-media-button">
                <FacebookIcon></FacebookIcon>
              </span>
              <span className="footer-media-button">
                <TwitterIcon></TwitterIcon>
              </span>
              <span className="footer-media-button">
                <LanguageIcon></LanguageIcon>
              </span>
            </div>
          </div>
        </StyledFooter>
      </div>
    <div className="bg-secondary w-full text-white text-center text-[12px] py-2">Privacy Policy | Terms of Use | © 2024 Grupo GM A Division of Grupo GM LLC. All Rights Reserved.</div>
    </section>
  );
};

export default Footer;